import {defineStore} from "pinia";
import axios from "axios";
import {encrypt, user_can} from "@/utils";
import Swal from "sweetalert2";
import {useAuthStore} from "@/state/pinia/auth";
import {useGlobalStore} from "@/state/pinia/global";
import {useSalesInvoicesStore} from "@/state/pinia/salesInvoices";
import {isNull} from "lodash";
import moment from "moment";
import router from "@/router";
import {translate} from "@/utils";

export const useCarsStore = defineStore("cars", {
    state: () => ({
        preventSuccessMsg: false,
        creditorsModal: false,
        fromSalesInvoice: false,
        car: {
            brand_id: null,
            brand: [],
            car_status: 'INCOMING',
            car_creditor_installments: {
                date: null,
                value: null
            },
            car_equipment: [],
            car_articles: [],
            car_guarantees: {
                last_done: {value: null, unit: 'KM'},
                gauge_setting: {value: null, unit: 'KM'}
            },
            car_inspections: {
                date: null,
                value: null,
                inspection_valid_until:null,
                road_confirmation:0
            },
            car_specifications: {
                month_of_manufacture: null,
                manufacture_year: null,
                co2_emissions: {value: null, unit: 'G/Km'},
                efect: {value: null, unit: 'KW'},
                fuel_consumption_mixed: {value: null, unit: '1/MILE'},
                max_load: {value: null, unit: 'KG'},
                max_trailer_weight: {value: null, unit: 'TONE'},
                total_weight: {value: null, unit: 'TONE'},
            },
            car_model: null,
            car_model_id: null,
            car_services: [],
            car_version_id: null,
            car_version: null,
            category: [],
            category_id: null,
            chassis_number: null,
            color: null,
            contact_person: {},
            contact_person_id: null,
            extra_price: null,
            first_reg_date: null,
            in_price: null,
            in_price_with_vat: null,
            in_stock_date: null,
            is_new_car: "0",
            meter_setting: {value: '', unit: "KM"},
            model_year: null,
            out_price: null,
            out_price_with_vat: null,
            registration_number: null,
            vat_method: [],
            vat_method_id: null,
            warehouse_location: null,

        },
        purchaseDetails: {
            country: null,
            reference: null,
            buyer: [],
            type: [],
            car: {}
        },
        serverItems: [],
        loading: true,
        NewPurchaseInvoiceStep: 1,
        NewCar: false,
        exchangeCustomerModal: false,
        searchedCustomer: [],
        totalItems: 0,
        ItemsList: [],
        itemsPerPage: 18,
        currentPage: 1,
        CurrentCar: {},
        apiPlurar: 'cars',
        apiSingle: 'item',
        activeProfileTab: 'car',
        CarServiceBook: [
            {date: '2022-03-23', meters: '2290km', desc: 'heööp wpröds'}
        ],
        ItemsInfo: {},
        per_page: 400,
        current_page: 1,
        filters: {
            registration_number: null
        },
        newItem: {},
        CurrentItemModal: false,
        UpdateItem: false,
    }),
    getters: {
        EquipmentsListGetter: (state) => {
            const globalStore = useGlobalStore()
            const allLists = globalStore.allLists

            if (Object.keys(allLists).length > 0) {

                if (Object.keys(state.CurrentCar).length > 0 && state.CurrentCar.car_equipment.length > 0) {

                    let equipments = state.CurrentCar.car_equipment;
                    let EquipmentsList = allLists['equipment']
                    const EquipmentsIdsMap = equipments.map(permission => {
                        return permission.id;
                    });
                    const filteredEquipmentsList = EquipmentsList.filter(permission =>
                        !EquipmentsIdsMap.includes(permission.id)
                    );
                    return filteredEquipmentsList;
                } else {
                    let EquipmentsList = allLists['equipment']
                    return EquipmentsList
                }
            } else {
                return []
            }

        },
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.header;
            return token
        },

    },
    actions: {
        async sendToBlocket() {
            try {
                let carId = this.CurrentCar.id;
                await axios.post(`${process.env.VUE_APP_API_URL}/cors/cars/blocket/${carId}`, {}, {
                    headers: this.token
                });
                await axios.post(`${process.env.VUE_APP_API_URL}/cors/cars/blocket/upload-images/${carId}`, {}, {
                    headers: this.token
                });
            } catch (error) {
                console.log(error);
                const errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },

        async mapCurrentCar(e, b) {
            const globalStore = useGlobalStore()
            await globalStore.fetchBulkList()
            let brands = globalStore.allLists['car-brands']
            let models = globalStore.allLists['car-models']
            console.log(e)
            let brand = brands.find(x => x.name.toLowerCase() === e.basic.data.make.toLowerCase())
            let brandId = brand.id
            let model = models.find(x => x.name.toLowerCase() === e.basic.data.model.toLowerCase())
            let modelId = model.id
            let color = e.basic.data.color
            let model_year = e.basic.data.vehicle_year
            console.log(model)
            let myCar = null
            if (b) {
                myCar = this.purchaseDetails.car
            } else {
                myCar = this.car
            }
            console.log(b)
            console.log(myCar)
            myCar.brand_id = brandId
            myCar.brand = brand
            myCar.color = color
            myCar.car_model = model
            myCar.car_model_id = modelId
            myCar.model_year = model_year
            myCar.chassis_number = e.attributes.vin
            myCar.meter_setting.value = e.inspection.data.meter
            myCar.meter_setting.unit = 'KM'
            myCar.car_inspections.date = e.inspection.data.latest_inspection
            myCar.car_inspections.value = e.status.data.tax
            myCar.car_inspections.inspection_valid_until =  e.inspection.data.inspection_valid_until
            myCar.car_inspections.road_confirmation = e.status.data.status === 1 ? 1 : 0
            myCar.first_reg_date = e.status.data.first_on_swedish_roads
            myCar.car_specifications = {
                "month_of_manufacture": e.status.data.manufacture_month !== null ? moment(e.status.data.manufacture_month).format('MM') : null,
                "manufacture_year": model_year,
                "efect": {"unit": "KW", "value": e.technical.data.power_kw_1},
                "fuel_consumption_mixed": {"unit": "1/MILE", "value": e.technical.data.consumption_1},
                "co2_emissions": {"unit": "G/Km", "value": e.technical.data.co2_1},
                "total_weight": {"unit": "TONE", "value": e.technical.data.total_weight},
                "max_load": {"unit": "KG", "value": e.technical.data.load_weight},
                "max_trailer_weight": {"unit": "TONE", "value": e.technical.data.trailer_weight},
                "number_of_passengers": e.technical.data.number_of_passengers,
                "environmental_classification": 'EURO ' + e.technical.data.euro_ncap,
                "car_body_id": 2,
                "gear_box_id": 2,
                "drive_method_id": 2,
                "primary_fuel": "Bensin",
                "secondary_fuel": "Bensin",
                "towbar": "1",

                "description": null,
            }


        },
        toggleexchangeCustomerModalModal() {
            this.exchangeCustomerModal = false
        },
        async search_car_reg(e) {
            const globalStore = useGlobalStore();
            try {
                const res = await axios.get(`${process.env.VUE_APP_API_URL}/cors/vehicle/${e}`, {
                    headers: this.token
                });

                await globalStore.fetchBulkList_();
                let self = this
                await setTimeout(function () {
                    if (res.data.result.data !== undefined) {
                        self.mapCurrentCar(res.data.result.data);
                    } else {
                        Swal.fire(translate("car_not_found"), '', "error");
                    }
                }, 1500)

            } catch (error) {
                console.log(error);
                Swal.fire(translate("error"), error.response, "error");
            }
        },
        async search_car_reg_purchase(e) {
            const globalStore = useGlobalStore();
            this.purchaseDetails.car.car_inspections = {
                date: null,
                value: null
            }
            this.purchaseDetails.car.car_specifications = {
                month_of_manufacture: null,
                manufacture_year: null,
                co2_emissions: {value: null, unit: 'G/Km'},
                efect: {value: null, unit: 'KW'},
                fuel_consumption_mixed: {value: null, unit: '1/MILE'},
                max_load: {value: null, unit: 'KG'},
                max_trailer_weight: {value: null, unit: 'TONE'},
                total_weight: {value: null, unit: 'TONE'},
            }
            try {
                const res = await axios.get(`${process.env.VUE_APP_API_URL}/cors/vehicle/${e}`, {
                    headers: this.token
                });

                await globalStore.fetchBulkList_();
                let self = this
                await setTimeout(function () {
                    if (res.data.result.data !== undefined) {
                        self.mapCurrentCar(res.data.result.data, true);
                    } else {
                        Swal.fire(translate("car_not_found"), '', "error");
                    }
                }, 1500)

            } catch (error) {
                console.log(error);
                Swal.fire(translate("error"), error.response, "error");
            }
        },
        retrive_customer(e) {
            axios.get(process.env.VUE_APP_API_URL + '/cors/customers/' + e, {
                    headers: this.token
                }
            ).then(res => {
                res.data.result.item.is_company = Boolean(parseInt(res.data.result.item.is_company))
                let item = res.data.result.item;
                this.CurrentCar.purchase_invoice.customer = item
            }).catch(error => {
                console.log(error)
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },

        prepareNewCar() {
            this.activeProfileTab = 'car'
            this.CurrentItemModal = true
            this.NewCar = true
            this.CurrentCar = {
                brand_id: null,
                brand: null,
                car_creditor_installments: {
                    date: null,
                    value: null
                },
                car_equipment: [],
                car_articles: [],
                car_guarantees: {
                    last_done: {value: null, unit: 'KM'},
                    gauge_setting: {value: null, unit: 'KM'}
                },
                car_inspections: {
                    date: null,
                    value: null,
                    inspection_valid_until:null,
                    road_confirmation:0
                },
                car_specifications: {
                    month_of_manufacture: null,
                    manufacture_year: null,
                    co2_emissions: {value: null, unit: 'G/Km'},
                    efect: {value: null, unit: 'KW'},
                    fuel_consumption_mixed: {value: null, unit: '1/MILE'},
                    max_load: {value: null, unit: 'KG'},
                    max_trailer_weight: {value: null, unit: 'TONE'},
                    total_weight: {value: null, unit: 'TONE'},
                },
                car_model: null,
                car_model_id: null,
                car_services: [],
                car_status: "INCOMING",
                car_version_id: null,
                car_version: null,
                category: {},
                category_id: null,
                chassis_number: null,
                color: null,
                contact_person: {},
                contact_person_id: null,
                extra_price: null,
                first_reg_date: null,
                in_price: null,
                in_price_with_vat: null,
                in_stock_date: null,
                is_new_car: "0",
                meter_setting: {value: null, unit: 'KM'},

                model_year: null,
                out_price: null,
                out_price_with_vat: null,
                registration_number: null,
                vat_method: null,
                vat_method_id: null,
                warehouse_location: null,


            }

        },
        deleteRow(roleId) {
            axios.delete(process.env.VUE_APP_API_URL + '/cors/cars/' + roleId, {
                    headers: this.token
                }
            ).then(res => {
                let role = this.ItemsList.findIndex(x => x.id == roleId)
                this.ItemsList.splice(role, 1)
                Swal.fire(translate('deleted'), translate('your_file_removed'), "success");
            }).catch(error => {
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        async createPurchaseInvoice() {
            try {
                this.UpdateItem = true;

                let customerId = this.searchedCustomer.id;
                let buyer_id = this.purchaseDetails.buyer.id;
                let reference_type = this.purchaseDetails.reference;
                let type_purchase = this.purchaseDetails.type.id;
                let country = this.searchedCustomer.country;
                let car_id = this.purchaseDetails.car.id;
                let purchase_note = this.purchaseDetails.purchase_note;

                let data = {
                    customer_id: customerId,
                    buyer_id: buyer_id,
                    reference_type: reference_type,
                    type_purchase: type_purchase,
                    country: country,
                    car_id: car_id,
                    purchase_note: purchase_note,
                    car: {purchase_date: this.purchaseDetails.car.purchase_date}
                };

                const res = await axios.post(process.env.VUE_APP_API_URL + '/invoicing/purchase_invoices', data, {
                    headers: this.token
                });
                router.push('/cars/' + this.purchaseDetails.car.id + '/' + res.data.result.item.id);
                this.UpdateItem = false;
            } catch (error) {
                console.log('error',error)
                this.UpdateItem = false;
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },

        async CreateItem(car, purchaseInvoice) {
            try {
                const hasPermission = await user_can('write_cars');
                if (hasPermission) {
                    this.UpdateItem = true;

                    let data = JSON.parse(JSON.stringify(car));
                    // Uncomment and modify if needed
                    // let car_equipment = JSON.parse(JSON.stringify(data.car_equipment));
                    // data.car_specifications.vdn_established = data.car_specifications.vdn_established ? '1' : '0';
                    // data.is_new_car = data.is_new_car ? '1' : '0';
                    // data.car_equipment = car_equipment.map((x) => x.id);

                    const res = await axios.post(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar, data, {
                        headers: this.token
                    });


                    this.CurrentItemModal = false;
                    this.ItemsList.unshift(res.data.result.item);
                    this.NewCar = false;

                    if (this.fromSalesInvoice) {
                        let salesInvoiceStore = useSalesInvoicesStore()
                        let itemSwap = {}
                        itemSwap.car = res.data.result.item
                        itemSwap.car_id = itemSwap.car.id
                        itemSwap.car_swap_price = itemSwap.car.in_price
                        salesInvoiceStore.currentItem.sales_invoice_swaps.push(itemSwap)
                        salesInvoiceStore.PutItem()
                    }

                    if (purchaseInvoice) {
                        this.purchaseDetails.car = res.data.result.item;
                        await this.createPurchaseInvoice()
                    } else {
                        this.UpdateItem = false;
                        //  router.push('/cars/' + res.data.result.item.id);
                    }
                    this.CurrentCar = {}
                    this.fromSalesInvoice = false
                }else {
                    // Handle the case where the user does not have permission
                    throw new Error('You do not have permission to create this item.');
                }
            } catch (error) {
                this.UpdateItem = false;
                const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
                Swal.fire(translate("error"), errorMessage, "error");
            }
        },

        getPurchasePaymentDetails(id) {

            return axios.get(process.env.VUE_APP_API_URL + '/invoicing/purchases/payments/get-by-purchase-invoice/', id, {
                headers: this.token
            }).then(res => {

            }).catch(error => {
                Swal.fire(translate("error"), error.response, "error");
            })

        },
        async SendPaymentToFortNox(itemId, index) {
            try {
                this.UpdateItem = true;
                await axios.post(process.env.VUE_APP_API_URL + '/cors/fortnox/create-purchaseInvoice', {
                    id: itemId
                }, {
                    headers: this.token
                }).then(res => {
                    this.UpdateItem = false;
                    this.CurrentCar.purchase_invoice.purchase_invoice_payments[index].fortnox_purchase_invoice_payment_id = res.data.result.fortnox_purchase_invoice_payment_id
                    Swal.fire(translate("success"), translate('Payment_sent_to_FortNox_successfully'), "success");
                })

            } catch (error) {
                this.UpdateItem = false;
                Swal.fire(translate("error"), error.response.data.message || translate('Failed_to_send_payment_to_FortNox'), "error");
                throw error; // Re-throw the error to be caught by the caller
            }
        },
        CreatePurchaseInvoice(data) {
            this.UpdateItem = true
            return axios.post(process.env.VUE_APP_API_URL + '/invoicing/purchases/payments', data, {
                headers: this.token
            }).then(res => {
                this.UpdateItem = false
                this.CurrentCar.purchase_invoice.purchase_invoice_payments.unshift(res.data.result.item)

            }).catch(error => {
                this.UpdateItem = false
                Swal.fire(translate("error"), error.response, "error");
            })

        },
        sendFileToScrive(id, type) {
            this.UpdateItem = true
            let data = {
                invoice_id: id,
                invoice_type: type
            }
            return axios.post(process.env.VUE_APP_API_URL + '/invoicing/scrive/start-signing', data, {
                headers: this.token
            }).then(res => {
                this.UpdateItem = false
                this.fetchItem(this.CurrentCar.id)
                // this.CurrentCar.purchase_invoice.purchase_invoice_payments.unshift(res.data.result.item)

            }).catch(error => {
                this.UpdateItem = false
                Swal.fire(translate("error"), error.response, "error");
            })

        },
        PutItem() {
            user_can('edit_cars').then(hasPermission => {
                if (hasPermission) {
                    this.UpdateItem = true
                    let itemId = this.CurrentCar.id
                    let data = JSON.parse(JSON.stringify(this.CurrentCar))
                    let car_equipment = JSON.parse(JSON.stringify(data.car_equipment))
                    data.car_specifications !== null ? data.car_specifications.vdn_established = data.car_specifications.vdn_established ? '1' : '0' : '0'
                       !isNull(data.car_specifications.gear_box) ? delete data.car_specifications.gear_box : ''
                       !isNull(data.car_inspections) && isNaN(new Date(data.car_inspections.inspection_valid_until)) ? data.car_inspections.inspection_valid_until = null : ''
                    data.is_new_car = data.is_new_car ? '1' : '0'
                    !isNull(data.car_creditor_installments) ? delete data.car_creditor_installments.controlled_by : ''
                    !isNull(data.car_creditor_installments) ? delete data.car_creditor_installments.creditor : ''
                    !isNull(data.car_creditor_installments) ? delete data.car_creditor_installments.paid_user : ''

                    !isNull(data.car_creditor_installments) ? delete data.car_creditor_installments.id : ''
                    !isNull(data.car_creditor_installments) ? delete data.car_creditor_installments.organization_number : ''


                    !isNull(data.car_guarantees) ? delete data.car_guarantees.created_at : ''
                    !isNull(data.car_equipment) &&  data.car_equipment.length > 0 ? data.car_equipment = data.car_equipment.map(x=>{
                        return x.id
                        }) : ''
                    !isNull(data.car_guarantees) ? delete data.car_guarantees.updated_at : ''
                    data.car_equexipment = car_equipment.map((x) => {
                        return x.id
                    })

                    return axios.put(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar + '/' + itemId, data, {
                        headers: this.token
                    }).then(res => {
                        this.UpdateItem = false
                        if (!this.preventSuccessMsg) {
                            Swal.fire(translate("success"), res.data.message, "success");
                        }
                        this.preventSuccessMsg = false

                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire(translate("error"), error.response.data.message, "error");
                    })
                }
            })

        },
        fetchList() {
            user_can('read_cars').then(hasPermission => {
                if (hasPermission) {
                    this.loading = true
                    return axios.get(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar, {
                        params: {
                            page: this.current_page,
                            per_page: this.per_page,
                            filters: this.filters
                        },
                        headers: this.token
                    }).then(res => {
                        this.ItemsList = res.data.result[this.apiPlurar].data
                        this.ItemsInfo = res.data.result[this.apiPlurar]
                        this.totalItems = res.data.result[this.apiPlurar].total
                        this.current_page = res.data.result[this.apiPlurar].current_page
                        this.per_page = res.data.result[this.apiPlurar].per_page
                        this.loading = false
                    }).catch(error => {
                        this.loading = false
                        console.log(error)
                        Swal.fire(translate("error"), error.response.data.message, "error");

                    })
                }
            })


        },
        fetchItem(e) {
            user_can('read_cars').then(hasPermission => {
                if (hasPermission) {
                    return axios.get(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar + '/' + e, {
                        headers: this.token
                    });
                } else {
                    throw new Error('No permission to read creditors.');
                }
            }).then(res => {
                let item = res.data.result[this.apiSingle];
                console.log(item)
                this.CurrentCar = item;
                isNull(this.CurrentCar.meter_setting) ? this.CurrentCar.meter_setting = {value: null, unit: null} : ''
                isNull(this.CurrentCar.car_inspections) ? this.CurrentCar.car_inspections = {
                    date: null,
                    value: null
                } : ''
                if (isNull(this.CurrentCar.car_creditor_installments)) {
                    this.CurrentCar.car_creditor_installments = {
                        creditor:[]
                    }
                }
                if (isNull(this.CurrentCar.car_specifications)) {
                    this.CurrentCar.car_specifications = {
                        month_of_manufacture: null,
                        manufacture_year: null,
                        co2_emissions: {value: null, unit: 'G/Km'},
                        efect: {value: null, unit: 'KW'},
                        fuel_consumption_mixed: {value: null, unit: '1/MILE'},
                        max_load: {value: null, unit: 'KG'},
                        max_trailer_weight: {value: null, unit: 'TONE'},
                        total_weight: {value: null, unit: 'TONE'},
                    }
                }

                if (isNull(this.CurrentCar.car_guarantees)) {
                    this.CurrentCar.car_guarantees = {
                        last_done: {value: null, unit: 'KM'},
                        gauge_setting: {value: null, unit: 'KM'}
                    }
                }
            }).catch(error => {
                console.log(error);
                Swal.fire(translate("error"), error.message || error.response.data.message, "error");
            });
        },
    },

});


