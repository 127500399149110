// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import moment from "moment";
import {user_can,translate} from "@/utils";


export const usePurchaseInvoicesStore = defineStore("purchaseInvoices", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 200,
        current_page: 1,
        filters: [],
        currentItem: {},
        newItem: {},
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar: 'invoicing/purchase_invoices',
        apiSingle: 'item',
        searchedCars: [],
        searchedCustomer: {},
        searchedBranch: null,
        searchedCar: {},
        FetchedPayment: {},
        expired_at: moment().add(7, 'days').format('YYYY-MM-DD'),
        Carfilters: {
            registration_number: null
        },
        paymentsItems: [],
    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.header;
            return token
        },
        seller: () => {
            const authStore = useAuthStore()
            const id = authStore.currentLoggedUser.user.id;
            return id
        },
    },
    mutations: {},
    actions: {
        toggleCurrentItemModal(state) {
            this.currentItem = {};
            this.CurrentItemModal = false;
        },
        updateItem(e, data) {
            this.UpdateItem = true
            axios.put(process.env.VUE_APP_API_URL + '/' + this.apiPlurar + '/' + e, data, {
                    headers: this.token
                }
            ).then(res => {
                let item = res.data.result[this.apiSingle];
                this.currentItem = item
                this.UpdateItem = false
            }).catch(error => {
                this.UpdateItem = false
                console.log(error)
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        fetchItem(e) {
            axios.get(process.env.VUE_APP_API_URL + '/' + this.apiPlurar + '/' + e, {
                    headers: this.token
                }
            ).then(res => {
                let item = res.data.result[this.apiSingle];
                this.currentItem = item
            }).catch(error => {
                console.log(error)
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        async fetchList() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/' + this.apiPlurar, {
                params: {
                    page: this.current_page,
                    per_page: this.per_page,
                    filters: this.filters
                },
                headers:this.token,
            }).then(res => {
                this.ItemsList = res.data.result.purchase_invoices.data
                this.ItemsInfo = res.data.result.purchase_invoices

                this.totalItems = res.data.result.purchase_invoices.total
                this.current_page = res.data.result.purchase_invoices.current_page
                this.per_page = res.data.result.purchase_invoices.per_page
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire(translate("error"), error.response.data.message, "error");

            })
        },
    },

});
