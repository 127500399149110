import { useLayoutStore } from "./pinia/layout";

import { useAuthStore } from "./pinia/auth";
import { useNotificationStore } from "./pinia/notification";
import { useCarsStore } from "./pinia/cars";
import { useSubscriptionsStore } from "./pinia/subscriptions";
import { useGlobalStore } from "./pinia/global";

import { useUsersStore } from "./pinia/users"
/*
import { useTransactionsStore } from "./pinia/transactions";
import { useCompaniesStore } from "./pinia/companies";
import { useAuthFakeStore } from "./pinia/authFake";
import { useCarKarossStore } from "./pinia/kaross"
import { useCarDrivningStore } from "./pinia/drivning"
import { useCarGearBoxStore } from "./pinia/gearbox";
import { useCarCategoriesStore } from "./pinia/carcategories";
import { useCarBrandStore } from "./pinia/carbrands"
import { useCarBrandModelStore } from "./pinia/carbrandsModel"
import { useCarBrandVersionStore } from "./pinia/carbrandsVersion"
import { useCarEquipmentsStore } from "./pinia/CarEquipments"
import { useVatStore } from "./pinia/vat"
 */
import { useCompaniesStore } from "./pinia/companies";
import { useBranchesStore } from "./pinia/branches"

import { useCreditsStore } from "./pinia/Credits"
import { useCarCommentStore } from "./pinia/comments"
import { useArticlesStore } from "./pinia/articles"
import { useDistirbutorsStore } from "./pinia/distirbutors"
import { useCustomersStore } from "./pinia/customers"
import { useSalesInvoicesStore } from "./pinia/salesInvoices"
import { usePurchaseInvoicesStore } from "./pinia/purchaseInvoices"
import { usePaymentMethodsStore } from "./pinia/paymentMethods"
import { useSettingsStore } from "./pinia/settings"
import { usePaymentsStore } from "./pinia/payments"
import { useEconomyStore } from "./pinia/economy"
import { useDocumentsStore } from "./pinia/carDocuments"
import { createPinia } from "pinia";
import {useUserDashboard} from "@/state/pinia/userDashboard";
import {useBlokerageStore} from "@/state/pinia/blockerage";
import {useTestDriveStore} from "@/state/pinia/testdrive";
import {useTestDriveSettingsStore} from "@/state/pinia/drivesettings";

const pinia = createPinia();
export default pinia;

export {useTestDriveSettingsStore,useTestDriveStore,useBlokerageStore,useUserDashboard,useDocumentsStore,useEconomyStore,usePurchaseInvoicesStore,usePaymentMethodsStore,usePaymentsStore,useSettingsStore,useSalesInvoicesStore,useCustomersStore,useDistirbutorsStore,useArticlesStore,useCarCommentStore,useCreditsStore,useBranchesStore,useUsersStore,useGlobalStore,useLayoutStore, useAuthStore, useNotificationStore,useCarsStore,useSubscriptionsStore };
