import {defineStore} from "pinia";
import {getFirebaseBackend} from '../../authUtils'
import axios from "axios";
import {Items} from "@/components/menu";
import {MetisMenu} from "metismenujs";
import {getToken, messaging} from "@/firebase";


function getParsedCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


function filterMenuItems(menuItems, userPermissions) {
    return menuItems
        .filter(item => {
            // Check if item is defined
            if (!item) return false;

            // Check if item's permissions match or if the item has no permissions
            const hasPermission = !item.permissions || item.permissions.length === 0 || item.permissions.some(permission => userPermissions.includes(permission));

            // Recursively filter subItems if they exist
            if (item.subItems && Array.isArray(item.subItems)) {
                item.subItems = filterMenuItems(item.subItems, userPermissions);
            }

            // Include the item if it has permission or if any of its subItems are included
            return hasPermission || (item.subItems && item.subItems.length > 0);
        });
    // eslint-disable-next-line no-unreachable

}





export const useAuthStore = defineStore("auth", {
    state: () => ({
        lang:'sv',
        currentUser: getParsedCookie('synqiacompany'),
        currentLoggedUser: {},
        MyBranch:[],
        menuItems: [
            {
                id: 2,
                label: "menuitems.cars.text",
                icon: "mdi-car-clutch",
                subItems: [
                    {
                        id: 'cars' + 1,
                        label: "menuitems.cars.list.all",
                        link: "/cars/list/all",
                        parentId: 2,
                        permissions: ['read_users']
                    },
                    {
                        id: 'cars' + 2,
                        label: "menuitems.cars.list.personal",
                        link: "/cars/list/personal",
                        parentId: 2,
                        permissions: ['read_users']
                    },
                    {
                        id: 'cars' + 3,
                        label: "menuitems.cars.list.transport",
                        link: "/cars/list/transport",
                        parentId: 2,
                        permissions: ['SELLER', 'ADMIN']
                    },
                    {
                        id: 'cars' + 4,
                        label: "menuitems.cars.list.transport",
                        link: "/cars/list/transport",
                        parentId: 2,
                        permissions: ['SELLER', 'ADMIN']
                    },
                    {
                        id: 'cars' + 5,
                        label: "menuitems.cars.list.transport",
                        link: "/cars/list/transport",
                        parentId: 2,
                        permissions: ['SELLER', 'ADMIN']
                    },
                    {
                        id: 'cars' + 6,
                        label: "menuitems.cars.list.transport",
                        link: "/cars/list/transport",
                        parentId: 2,
                        permissions: ['SELLER', 'ADMIN']
                    },
                    {
                        id: 'cars' + 7,
                        label: "menuitems.cars.list.transport",
                        link: "/cars/list/transport",
                        parentId: 2,
                        permissions: ['SELLER', 'ADMIN']
                    },
                    {
                        id: 'cars' + 8,
                        label: "menuitems.cars.list.transport",
                        link: "/cars/list/transport",
                        parentId: 2,
                        permissions: ['SELLER', 'ADMIN']
                    },
                ]
            },
            {
                id: 4,
                label: "menuitems.invoices.text",
                icon: "mdi-account-credit-card-outline",
                subItems: [
                    {
                        id: 'invoices' + 1,
                        label: "menuitems.invoices.list.sellInvoice",
                        link: "/invoices/list",
                        parentId: 4,
                        permissions: []
                    },
                    {
                        id: 'invoices' + 3,
                        label: "menuitems.invoices.list.offer",
                        link: "/offers/list",
                        parentId: 4,
                        permissions: []
                    },
                    {
                        id: 'invoices' + 2,
                        label: "menuitems.invoices.list.buyInvoice",
                        link: "/invoices/purchase_invoices",
                        parentId: 4,
                        permissions: []
                    },
                    {
                        id: 'invoices' + 3,
                        label: "menuitems.invoices.list.blockerage",
                        link: "/invoices/blockerage",
                        parentId: 4,
                        permissions: []
                    },
                    {
                        id: 'invoices' + 4,
                        label: "menuitems.invoices.list.testdrive",
                        link: "/invoices/testdrive",
                        parentId: 4,
                        permissions: []
                    }
                ]
            },

            {
                id: 6,
                label: "menuitems.economy.text",
                icon: "mdi-podium-silver",
                subItems: [
                    {
                        id: 'economy' + 7,
                        label: "menuitems.economy.list.upparbetat",
                        link: "/rapport/economy/upparbetat",
                        parentId: 4,
                        permissions: ['read_users']
                    },
                    /*{
                        id: 'economy' + 8,
                        label: "menuitems.economy.list.faktura_underlag",
                        link: "/rapport/economy/fakturaunderlag",
                        parentId: 4,
                        permissions: ['read_users']
                    },*/
                ]
            },
            {
                id: 3,
                label: "menuitems.contacts.text",
                icon: "mdi-account-group-outline",
                subItems: [
                    {
                        id: 'contacts' + 1,
                        label: "menuitems.contacts.list.persons",
                        link: "/customers/list",
                        parentId: 3,
                        permissions: ['read_customers']
                    },
                    {
                        id: 'contacts' + 1,
                        label: "menuitems.contacts.list.brokerage",
                        link: "/customers/brokerage",
                        parentId: 3,
                        permissions: ['read_customers']
                    },
                ]
            },

            {
                id: 6,
                label: "menuitems.users.text",
                icon: "mdi-face-agent",
                link: "/users",
                permissions: ['read_users']
            },
            {
                id: 10,
                label: "menuitems.distirbutors.text",
                icon: "mdi-account-hard-hat-outline",
                link: "/distributors",
                permissions: ['read_distributors']
            },
            {
                id: 9,
                label: "menuitems.articles.text",
                icon: "mdi-format-list-group",
                link: "/articles",
                permissions: ['read_articles']
            },
            {
                id: 7,
                label: "menuitems.companies.list.transactions",
                icon: "mdi-receipt-text-arrow-left-outline",
                link: "/transactions",
                permissions: ['read_subscriptions']
            },
            {
                id: 9,
                label: "Rapports",
                icon: "mdi-cog-play-outline",
                subItems: [
                    {
                        id: 'Branches' + 1,
                        label: "Cars",
                        link: "/rapport/cars",
                        parentId: 8,
                        permissions: ['read_reports']
                    },
                ]
            },
            {
                id: 8,
                label: "menuitems.settings.text",
                icon: "mdi-cog-play-outline",
                subItems: [
                    {
                        id: 'Branches' + 1,
                        label: "menuitems.branches.branches",
                        link: "/settings/branches",
                        parentId: 8,
                        permissions: ['read_branches']
                    },
                    {
                        id: 'Creditors' + 2,
                        label: "menuitems.creditors.creditors",
                        link: "/settings/creditors",
                        parentId: 8,
                        permissions: ['read_creditors']
                    },
                    {
                        id: 'Settings' + 3,
                        label: "menuitems.settings.text",
                        link: "/settings/settings",
                        parentId: 8,
                        permissions: ['edit_setting']
                    },
                    {
                        id: 'PaymentMethods' + 4,
                        label: "paymentMethods.title",
                        link: "/settings/payment_methods",
                        parentId: 8,
                        permissions: ['edit_setting']
                    },
                    {
                        id: 'inspections_checklist' + 5,
                        label: "inspections_checklist",
                        link: "/settings/inspections_checklist",
                        parentId: 8,
                        permissions: ['edit_setting']
                    },
                ]
            },
        ],
        fcm_token: null,
        fcm_error: null,

    }),
    getters: {
        loggedIn() {
            return !!this.currentUser
        },
        header() {
            return {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + this.currentUser,
                'accept-language':this.lang,
            }
        }

    },
    actions: {
        commit_my_branch(payload){
            this.currentLoggedUser.branches.forEach((x)=>{
                x.is_current = 0
            })
            payload.is_current = 1
            this.MyBranch = payload
            axios.get(process.env.VUE_APP_API_URL+'/users/auth/select-branch/'+payload.id,{
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.currentUser,
                    'accept-language':this.lang,
                }
            }).then(res=>{
                console.log(res.data)
            }).catch(error=>{
                window.alert(error.response.data.message)
            })
        },
        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },


        logIn({email, password}) {
            return getFirebaseBackend().loginUser(email, password).then(() => {
                this.validate()
            })
        },

        getFcm(){
            getToken(messaging, { vapidKey: 'BCgpZAAhq3kpBn9jg12xi4_1Ehyx2Nb8qnyqKEsY2EcGNms9JFOokeVXD_oOqVrkJ2bgIEHHDEInVVXz9u-4czs' }).then(res => {
                this.fcm_token = res
                console.log(res)
                console.log(this.fcm_token)
            }).catch(error => {
                this.fcm_error = error
                console.log('Error getting FCM token:', error)
            })
        },
        async getUser() {
            let tokenId = getParsedCookie('synqiacompany');
            let token = tokenId;
            if (token !== undefined && token !== null) {
                try {
                    const response = await axios.get(process.env.VUE_APP_API_URL + "/users/auth/me", {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + token,
                            'fcm_token': this.fcm_token,
                        },
                        params: {
                            'fcm_token': this.fcm_token
                        }
                    });

                    this.currentLoggedUser = response.data.result;
                    const userRole = this.currentLoggedUser.permissions;

                    if (userRole !== undefined) {
                        // Your role-based logic here
                        const userPermissions = userRole.map(x => {
                            return x.name
                        }); // Your permissions array
                        filterMenuItems([...this.menuItems], userPermissions);

                    }
                    const branches = this.currentLoggedUser.branches;
                    if (branches !== undefined) {
                        // Your role-based logic here
                        const userCurrentBrach = branches.find(x => {
                            return x.is_current
                        }); // Your permissions array
                        this.MyBranch = userCurrentBrach
                    }
                } catch (error) {
                    document.cookie = 'synqiacompany=;max-age=0; path=/';
                    // Handle error, e.g., redirect or log
                }
            }
        },

        setUser(user) {
            this.currentUser = user
            this.saveState('synqiacompany', user)


        },
        saveState(key, state) {
            this.setCookie(key, state.token, 7)
        },


        logOut() {
            this.setUser(null)
            return new Promise((resolve, reject) => {
                getFirebaseBackend().logout().then(() => {
                    resolve(true);
                }).catch((error) => {
                    reject(this._handleError(error));
                })
            });
        },
    },
})
