import { useAuthStore, useAuthFakeStore } from "@/state/pinia";
import {translate} from "@/utils";



export default [
  {
    path: "/",
    name: "default",
    meta: { title: "Dashboard", authRequired: true },
    component: () => import("../views/dashboards/default")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/account/logout"),
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        const authFake = useAuthFakeStore();

        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          auth.logOut();
        } else {
          authFake.logout();
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        // user_can(routeTo.meta.permissions)
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      }
    }
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "404 Error Page" },
    component: require("../views/utility/404").default
  },
  {
    path: "/dashboard/saas",
    name: "saas-dashboard",
    meta: { title: "Saas Dashboard", authRequired: true ,  permissions: true},
    component: () => import("../views/dashboards/saas/index")
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    meta: { title: "Subscriptions", authRequired: true ,  permissions:  'edit_setting'},
    component: () => import("../views/subscriptions/subscriptions")
  },
  {
    path: "/users",
    name: "users",
    meta: { title: "Users", authRequired: true,
     permissions: true
    },
    component: () => import("../views/users/users")
  },
  {
    path: "/articles",
    name: "articles",
    meta: { title: "Articles", authRequired: true,
     permissions: true
    },
    component: () => import("../views/articles/articles")
  },
  {
    path: "/distributors",
    name: "Distributors",
    meta: { title: "distributors", authRequired: true,
     permissions: true
    },
    component: () => import("../views/distirbutor/distirbutors")
  },
  {
    path: "/transactions",
    name: "transactions",
    meta: { title: "Transactions", authRequired: true ,permissions: true},
    component: () => import("../views/subscriptions/subscriptions")
  },
  {
    path: '/settings/cars/categories',
    name: 'CarCategories',
    meta: {authRequired: true,title: "Car categories",permissions: true },
    component: () => import("../views/carsettings/categories")
  },
  {
    path: "/cars/list/all",
    name: "Allcarslist",
    meta: { title: "Contact Users List", authRequired: true,permissions:true },
    component: () => import("../views/cars/list")
  },
  {
    path: "/cars/:id",
    name: "Car profile data",
    meta: { title: "Car profile data", authRequired: true,permissions:true },
    component: () => import("../views/cars/CarProfile")
  },
  {
    path: "/cars/:id/:purchaseid",
    name: "PurchaseInvoice data",
    meta: { title: "PurchaseInvoice data", authRequired: true,permissions:true },
    component: () => import("../views/cars/CarProfile")
  },
  {
    path: "/cars/list/personal",
    name: "Car list personal",
    meta: { title: "Contact Users List", authRequired: true,permissions:  true },
    component: () => import("../views/cars/list")
  },
  {
    path: "/cars/list/transport",
    name: "Car list transport",
    meta: { title: "Contact Users List", authRequired: true,permissions:  true },
    component: () => import("../views/cars/list")
  },
  {
    path: "/contacts/profile",
    name: "Profile",
    meta: { title: "Profile", authRequired: true , permissions:  true},
    component: () => import("../views/account/personal-profile")
  },
  {
    path: "/customers/list",
    name: "CustomersList",
    meta: { title: "Customers", authRequired: true , permissions:  true},
    component: () => import("../views/customers/list")
  },
  {
    path: "/customers/brokerage",
    name: "BrokerageList",
    meta: { title: "BrokerageList", authRequired: true , permissions:  true},
    component: () => import("../views/customers/brokeragelist")
  },
  {
    path: "/settings/branches",
    name: "Branches",
    meta: {authRequired: true},
    component: () => import("../views/branches/branches"),

  },
  {
    path: "/settings/payment_methods",
    name: "paymentMethods",
    meta: {authRequired: true},
    component: () => import("../views/settings/payment_methods"),

  },
  {
    path: "/invoices/list",
    name: "salesInvoices",
    meta: {authRequired: true},
    component: () => import("../views/invoices/list"),
  },
  {
    path: "/invoices/blockerage",
    name: "BlockerageAgreements",
    meta: {authRequired: true, title: translate('menuitems.contacts.list.brokerage')},
    component: () => import("../views/blockerageAgreements/list"),
  },
  {
    path: "/invoices/blockerage/:id",
    name: "BlockerageAgreementsinvoice",
    meta: {authRequired: true, title: translate('menuitems.contacts.list.brokerage')},
    component: () => import("../views/blockerageAgreements/blockerageInvoice"),
  },
  {
    path: "/offers/list",
    name: "salesOffers",
    meta: {authRequired: true},
    component: () => import("../views/invoices/offersList"),
  },
  {
    path: "/invoices/purchase_invoices",
    name: "purchaseInvoices",
    meta: {authRequired: true},
    component: () => import("../views/invoices/purchase_invoices_list"),
  },
  {
    path: "/invoices/invoice/:id",
    name: "singleInvoice2",
    meta: {authRequired: true},
    component: () => import("../views/invoices/singleInvoice"),
  },

  {
    path: "/invoices/invoices/:id",
    name: "singleInvoice",
    meta: {authRequired: true},
    component: () => import("../views/invoices/salesInvoiceTemplate"),
  },
  {
    path: "/invoices/invoices/offers/:id",
    name: "singleOffer",
    meta: {authRequired: true},
    component: () => import("../views/invoices/offerTemplate"),
  },
  {
    path: "/invoices/invoices/vdn/:id",
    name: "singleVdn",
    meta: {authRequired: true},
    component: () => import("../views/invoices/components/vdn"),
  },
  {
    path: "/invoices/invoices/purchase/:id",
    name: "singlePurchase",
    meta: {authRequired: true},
    component: () => import("../views/invoices/purchaseTemplate"),
  },
  {
    path: "/invoices/payments/:id",
    name: "paymentInvoice",
    meta: {authRequired: true},
    component: () => import("../views/invoices/paymentInvoice"),
  },
  {
    path: "/settings/creditors",
    name: "Creditors",
    meta: { title: "Creditors", authRequired: true , permissions:  'read_creditor'},
    component: () => import("../views/creditors/creditors")
  },
  {
    path: "/settings/settings",
    name: "SettingsPage",
    meta: { title: "SettingsPage", authRequired: true , permissions:  'edit_settings'},
    component: () => import("../views/settings/settings")
  },
  {
    path: "/rapport/economy/upparbetat",
    name: "Economy upparbetat222",
    meta: { title: "Economy upparbetat", authRequired: false , permissions:  'edit_reports'},
    component: () => import("../views/economy/upparbetat")
  },
  {
    path: "/rapport/cars",
    name: "carsRapport",
    meta: { title: "carsRapport", authRequired: false , permissions:  'edit_reports'},
    component: () => import("../views/rapports/cars")
  },
  /*{
    path: "/economy/fakturaunderlag",
    name: "Economy fakturaunderlag",
    meta: { title: "Economy fakturaunderlag", authRequired: true , permissions:  'edit_reports'},
    component: () => import("../views/economy/fakturaunderlag")
  }*/

];
