// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore, useCarsStore, useSalesInvoicesStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import sv_state from "@/assets/jsons/sv_state.json";
import {translate} from "@/utils";

export const useCustomersStore = defineStore("customers", {
    state: () => ({
        sv_lans: sv_state,
        loading: true,
        NewCustomerModal: false,
        totalItems: 0,
        BrokerageTotalItems: 0,
        ItemsList: [],
        BrokerageItemsInfo: {},
        BrokerageItemsList: [],
        ItemsInfo: {},
        per_page: 200,
        current_page: 1,
        filters: {name: ''},
        currentItem: {},
        newItem: {
            is_company: 0,
            dialCode: {"country": "Sweden", "dial_code": "+46"}
        },
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar: 'customers',
        apiSingle: 'item',
        origin: null

    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.header;
            return token
        },
    },
    actions: {
        fetchUserByPSN(psn) {
            this.UpdateItem = true
            axios.get(process.env.VUE_APP_API_URL + '/cors/customers/get-personal-info/' + psn, {
                    headers: this.token
                }
            ).then(res => {
                this.UpdateItem = false
                if (res.data.result.length > 0) {
                    let result = res.data.result[0]
                    let currentAddressData = result.Folkbokforingsadress[0]
                    let currentAddressData2 = result.Folkbokforing[0]
                    this.newItem.name = result.Namn.Fornamn + ' ' + result.Namn.Efternamn,
                        this.newItem.address = currentAddressData.Utdelningsadress2,
                        this.newItem.zipcode = currentAddressData.PostNr,
                        this.newItem.city1 = currentAddressData.Postort
                    this.newItem.state = this.sv_lans.countries.find(x => x.code === currentAddressData2.FolkbokfordLanKod)
                    let city = this.newItem.state.municipalities.find(x => x.code === currentAddressData2.FolkbokfordLanKod + '' + currentAddressData2.FolkbokfordKommunKod)
                    this.newItem.city = city.name
                }

            }).catch(error => {
                this.UpdateItem = false
                console.log(error)
            })
        },
        CreateNewItem() {
            this.UpdateItem = true
            let data = {...this.newItem}
            data.is_company ? data.is_company = 1 : data.is_company = 0
            data.state = this.newItem.state.name
            data.city = this.newItem.city.name
            data.phone1 = this.newItem.dialCode.dial_code + '' + this.newItem.phone1
            if (data.phone2 !== undefined && data.phone2 !== null && data.phone2.length > 6) {
                data.phone2 = this.newItem.dialCode.dial_code + '' + this.newItem.phone2
            }


            axios.post(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar, data, {
                    headers: this.token
                }
            ).then(res => {
                this.UpdateItem = false
                this.ItemsList.push(res.data.result[this.apiSingle])
                if (this.origin === 'sellInvoice') {
                    const sellInvoice = useSalesInvoicesStore()
                    sellInvoice.searchedCustomer = res.data.result[this.apiSingle]
                }
                if (this.origin === 'carStore') {
                    const CarStore = useCarsStore()
                    CarStore.searchedCustomer = res.data.result[this.apiSingle]
                    this.newItem = {}
                    this.NewCustomerModal = false
                }
                Swal.fire(translate("success"), res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        CreateBrokerageNewItem() {
            this.UpdateItem = true
            let data = {...this.newItem}
            data.state = this.newItem.state.name
            data.city = this.newItem.city.name
            data.phone1 = this.newItem.dialCode.dial_code + '' + this.newItem.phone1
            if (data.phone2 !== undefined && data.phone2 !== null && data.phone2.length > 6) {
                data.phone2 = this.newItem.dialCode.dial_code + '' + this.newItem.phone2
            }

            data.is_company ? data.is_company = 1 : data.is_company = 0
            axios.post(process.env.VUE_APP_API_URL + '/invoicing/brokeragers' , data, {
                    headers: this.token
                }
            ).then(res => {
                this.UpdateItem = false
                this.BrokerageItemsList.push(res.data.result.item)
                Swal.fire(translate("success"), res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        PutItem() {
            this.UpdateItem = true
            let itemId = this.currentItem.id
            let data = {...this.currentItem}
            data.is_company ? data.is_company = 1 : data.is_company = 0
            axios.put(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar + '/' + itemId, data, {
                    headers: this.token
                }
            ).then(res => {
                this.UpdateItem = false
                Swal.fire(translate("success"), res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        fetchItem(e) {
            axios.get(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar + '/' + e, {
                    headers: this.token
                }
            ).then(res => {
                res.data.result[this.apiSingle].is_company = Boolean(parseInt(res.data.result[this.apiSingle].is_company))
                let item = res.data.result[this.apiSingle];
                this.currentItem = item
            }).catch(error => {
                console.log(error)
                Swal.fire(translate("error"), error.response.data.message, "error");
            })
        },
        async fetchBrokerageItem(itemId) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/invoicing/brokeragers/${itemId}`, {
                    headers: this.token,
                });

                const item = response.data.result.item;
                this.currentItem = item;

                // Return the item for any additional component-specific logic
                return {
                    success: true,
                    item,
                };

            } catch (error) {
                console.error('Error fetching item:', error);

                return {
                    success: false,
                    message: error.response?.data?.message || 'An error occurred while fetching the item.',
                };
            }
        },
        async PutBrokerageItem(itemId) {
            try {
                let data = {...this.currentItem}
                const response = await axios.put(`${process.env.VUE_APP_API_URL}/invoicing/brokeragers/${itemId}`, data,{
                    headers: this.token,
                });

                const item = response.data.result.item;
                this.currentItem = item;

                // Return the item for any additional component-specific logic
                return {
                    success: true,
                    item,
                };

            } catch (error) {
                console.error('Error fetching item:', error);

                return {
                    success: false,
                    message: error.response?.data?.message || 'An error occurred while fetching the item.',
                };
            }
        },
        async fetchList() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar, {
                params: {
                    page: this.current_page,
                    per_page: this.per_page,
                    filters: this.filters
                },
                headers: this.token,
            }).then(res => {
                this.ItemsList = res.data.result[this.apiPlurar].data
                this.ItemsInfo = res.data.result[this.apiPlurar]

                this.totalItems = res.data.result[this.apiPlurar].total
                this.current_page = res.data.result[this.apiPlurar].current_page
                this.per_page = res.data.result[this.apiPlurar].per_page
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire(translate("error"), error.response.data.message, "error");

            })
        },
        async fetchBrokerageList() {
            try {
                this.loading = true;
                const res = await axios.get(process.env.VUE_APP_API_URL + '/invoicing/brokeragers', {
                    params: {
                        page: this.current_page,
                        per_page: this.per_page,
                        filters: this.filters
                    },
                    headers: this.token,
                });
                this.BrokerageItemsList = res.data.result.brokeragers.data;
                this.BrokerageTotalItems = res.data.result.brokeragers.total;
                this.current_page = res.data.result.brokeragers.current_page;
                this.per_page = res.data.result.brokeragers.per_page;
            } catch (error) {
                console.log(error);
                Swal.fire(translate("error"), error.response.data.message, "error");
            } finally {
                this.loading = false;
            }
        }

    },

});
